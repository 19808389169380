import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const AuthContext = createContext();

// const URL = "http://116.203.206.211:5000";

// const URL = 'http://195.35.23.51:3001';
const URL = 'https://sqlguroo.com';

;

export const AuthProvider = ({ children }) => {
    const [userId, setUserId] = useState(
        () => localStorage.getItem("userId") || null
    );
    const [firstName, setFirstName] = useState(
        () => localStorage.getItem("firstName") || ""
    );

    const login = async (username, password) => {
        const response = await axios.post(
            `${URL}/auth/login`,
            {
                username,
                password,
            },
            { withCredentials: true }
        );

        const { id, firstName: userFirstName } = response.data;
        setUserId(id);
        setFirstName(userFirstName);

        // Store in localStorage
        localStorage.setItem("userId", id);
        localStorage.setItem("firstName", userFirstName);
    };

    const logout = async () => {
        await axios.post(`${URL}/auth/logout`, {}, { withCredentials: true });
        setUserId(null);
        setFirstName("");

        // Remove from localStorage
        localStorage.removeItem("userId");
        localStorage.removeItem("firstName");
    };

    useEffect(() => {
        // Check if user is logged in on initial load
        const id = localStorage.getItem("userId");
        const storedFirstName = localStorage.getItem("firstName");
        if (id) {
            setUserId(id);
            setFirstName(storedFirstName);
        }
    }, []);

    return (
        <AuthContext.Provider value={{ userId, firstName, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};
