import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { EditorView, keymap } from "@codemirror/view";
import Split from 'react-split'
import DataTable from './DataTable'; // Import your DataTable component
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams } from 'react-router-dom';
import Home from './Home';
// const deepEqual = require('deep-equal');
// var deepEqual = require('fast-deep-equal');
import { FcPrevious, FcNext } from 'react-icons/fc';
import { BsListCheck } from 'react-icons/bs';
import { BiLogoPostgresql } from "react-icons/bi";
import { SiPostgresql } from "react-icons/si";
import { ToastContainer, toast } from 'react-toastify';
import { FaPlay } from "react-icons/fa6";

import 'react-toastify/dist/ReactToastify.css';
import logo from './logo_img.png';


const lessons_array = [
    {
        "id": 1,
        "Lesson": "1. SELECT All"
    },
    {
        "id": 2,
        "Lesson": "2. SELECT column"
    },
    {
        "id": 3,
        "Lesson": "3. WHERE"
    },
    {
        "id": 4,
        "Lesson": "4. ORDER BY"
    },
    {
        "id": 5,
        "Lesson": "5. LIKE"
    },
    {
        "id": 6,
        "Lesson": "6. IN"
    },
    {
        "id": 7,
        "Lesson": "7. BETWEEN"
    },
    {
        "id": 8,
        "Lesson": "8. AND"
    },
    {
        "id": 9,
        "Lesson": "9. OR"
    },
    {
        "id": 10,
        "Lesson": "10. NOT"
    },
    {
        "id": 11,
        "Lesson": "11. IS NULL"
    },
    {
        "id": 12,
        "Lesson": "12. COUNT"
    },
    {
        "id": 13,
        "Lesson": "13. SUM"
    },
    {
        "id": 14,
        "Lesson": "14. MAX/MIN"
    },
    {
        "id": 15,
        "Lesson": "15. AVG "
    },
    {
        "id": 16,
        "Lesson": "16. GROUP BY"
    },
    {
        "id": 17,
        "Lesson": "17. HAVING"
    },
    {
        "id": 18,
        "Lesson": "18. CASE"
    },
    {
        "id": 19,
        "Lesson": "19. DISTINCT"
    },
    {
        "id": 20,
        "Lesson": "20. INNER JOIN"
    },
    {
        "id": 21,
        "Lesson": "21. FULL JOIN"
    },
    {
        "id": 22,
        "Lesson": "22. LEFT JOIN"
    },
    {
        "id": 23,
        "Lesson": "23. RIGHT JOIN"
    },
    {
        "id": 24,
        "Lesson": "24. UNION"
    },
    {
        "id": 25,
        "Lesson": "25. UNION ALL"
    },
    {
        "id": 26,
        "Lesson": "26. RANK"
    },
    {
        "id": 27,
        "Lesson": "27. DENSE_RANK"
    },
    {
        "id": 28,
        "Lesson": "28. ROW_NUMBER"
    }
]


function Learnsql() {
    const [sqlQuery, setSqlQuery] = useState('');
    const [results, setResults] = useState([]);
    const [correct, setCorrect] = useState('');
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState();
    const [queryerror, setQueryerror] = useState('');
    const { id } = useParams();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 650);
    const [selectedItem, setSelectedItem] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 650);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    const backendUrl = 'http://195.35.23.51:3001';


    const handlePrevPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const prevPage = parseInt(id, 10) - 1;
        // Redirect to the next page
        if (prevPage < 1) {
            return;
        }
        window.location.href = `/learnsql/${prevPage}`;
    };

    const handleNextPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const nextPage = parseInt(id, 10) + 1;
        // Redirect to the next page
        if (nextPage >= 10000) {
            return;
        }
        window.location.href = `/learnsql/${nextPage}`;
    };



    const handleQueryChange = (e) => {
        setSqlQuery(e);
    };



    const getLessonData = async () => {
        try {
            const response = await axios.get(`https://sqlguroo.com/api/get-lesson/${id}`);
            const specificData = response.data;
            setQuestion(specificData)
            console.log(specificData);
        } catch (error) {
            console.error('Error fetching specific data:', error);
        }
    };



    const executeQuery = async () => {
        setLoading(true);
        setCorrect('')
        setButtonDisabled(true);

        setTimeout(async () => {

            if (/DROP|ALTER/i.test(sqlQuery)) {
                setQueryerror('Only Select statements are allowed');
                console.log('Only Select statements are allowed')
                setResults([])
                setTimeout(async () => {
                    setButtonDisabled(false);
                }, 1400);
                return;
            }

            try {

                // let res = await axios.post('https://sqlguru-service.onrender.com/executeQuery/', { sqlQuery })

                let res = await axios.post('https://sqlguroo.com/api/executeQuery/', { sqlQuery }
                    , {
                        timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
                    }
                )

                let { data } = res.data;
                setLoading(false);
                setResults(res.data)
                // console.log(res.data)
                console.log(results)
                setQueryerror('')
            }
            catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled:', e.message)
                    setQueryerror(e.message)
                    setResults([])
                } else {
                    console.log(e.response ? e.response.data.error : e)
                    setQueryerror(e.response ? e.response.data.error : 'Unknown Error')
                    setResults([])
                }
            }

            setButtonDisabled(false);

        }, 1400);
    };


    function areTablesEqual(tableA, tableB) {
        // Check if the tables have the same number of rows
        if (tableA.length !== tableB.length) {
            return false;
        }

        // Create arrays to store sorted row data for comparisonss
        const sortedTableA = [];
        const sortedTableB = [];

        // Sort the rows in both tables by their values
        for (const row of tableA) {
            const sortedRow = Object.values(row).sort();
            sortedTableA.push(sortedRow);
        }

        for (const row of tableB) {
            const sortedRow = Object.values(row).sort();
            sortedTableB.push(sortedRow);
        }

        // Sort the entire array to ensure consistent order
        sortedTableA.sort();
        sortedTableB.sort();

        // Compare the sorted arrays
        for (let i = 0; i < sortedTableA.length; i++) {
            for (let j = 0; j < sortedTableA[i].length; j++) {
                if (sortedTableA[i][j] !== sortedTableB[i][j]) {
                    return false;
                }
            }
        }

        return true;
    }




    const submitQuery = async () => {
        if (results.length !== 0 && question !== null) {
            // Perform your processing on 'result' here
            // For example, you can log it or update other state variables

            setButtonDisabled(true)
            setCorrect('')

            setTimeout(async () => {

                try {
                    const jsonString1 = JSON.stringify(question[0].output, null, 2);
                    const jsonString2 = JSON.stringify(results, null, 2);

                    console.log(jsonString1)
                    console.log(jsonString2)

                    // const areEqual = jsonString1 === jsonString2;

                    const areEqual = areTablesEqual(question[0].output, results);


                    if (areEqual) {
                        setCorrect('Correct');
                    }
                    else {
                        setCorrect('Incorrect');
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setCorrect('false');
                }
                setButtonDisabled(false)

            }, 1400);

        }
    };




    useEffect(() => {
        getLessonData()
    }
        , []);


    const KEYWORDS = [
        "select",
        "from",
        "where",
        "and",
        "or",
        "not",
        "in",
        "between",
        "contains",
        "array",
        "limit",
        "offset",
        "union",
        "intersect",
        "except",
        "order by",
        "asc",
        "desc"
    ];

    const TENSORS = ["images", "labels"];

    const dialect = SQLDialect.define({
        keywords: KEYWORDS.join(" "),
        builtin: TENSORS.join(" ")
    });



    return (
        <div className="relative flex h-screen overflow-hidden justify-center">

            {isSmallScreen ? (
                // Render this div for small screens

                <div className="relative flex flex-col h-screen overflow-hidden ">

                    <div className="navbar bg-base-100">
                        <div className="navbar-start">
                            <Link to="/" >
                                {/* <a className="btn btn-ghost normal-case text-xl"> SQL Guroo</a> */}
                                <img src={logo} alt="Logo" className="w-64 sm:mx-auto" />
                            </Link>


                        </div >

                        <div className="navbar-end">
                            <h1 className="normal-case font-semibold hidden sm:block text-base xl:text-xl">
                                Practice, Learn, and Master SQL!
                            </h1>
                        </div>

                    </div >


                    <div className="flex justify-center">
                        <nav aria-label="Pagination">
                            <ul className="inline-flex items-center space-x-1 rounded-md text-sm">
                                <li>
                                    <button onClick={handlePrevPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                        </svg>
                                        <span>Previous</span>
                                    </button>
                                </li>
                                <li>
                                    <span className="inline-flex items-center rounded-md bg-white px-4 py-2 text-gray-500">Lesson <b className="mx-1">{id}</b> of <b className="ml-1">28</b></span>
                                </li>
                                <li>
                                    <button onClick={handleNextPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                        <span>Next</span>
                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>


                    <div class="mx-auto w-full h-full overflow-auto p-2">
                        <div class="h-full divide-y divide-gray-100 overflow-hidden shadow-sm">


                            <Split
                                // class="wrap"
                                sizes={[40, 60]}
                                minSize={100}
                                expandToMin={false}
                                gutterSize={6}
                                gutterAlign="center"
                                snapOffset={30}
                                dragInterval={1}
                                direction="vertical"
                                cursor="col-resize"
                                className="flex flex-col overflow-hidden h-full p-1"
                            >

                                <div className="border border-gray-300 rounded-md bg-white shadow-sm p-1 overflow-auto">

                                    <div className="overflow-auto   flex flex-row justify-between">


                                        <dialog id="my_modal_3" className="modal">
                                            <div className="modal-box">
                                                <form method="dialog">
                                                    {/* if there is a button in form, it will close the modal */}
                                                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                                </form>
                                                <h3 className="font-bold text-lg">PostgreSQL Syntax</h3>
                                                <p className="py-4">1. Use PostgreSQL 14 version for SQL syntax</p>
                                                {/* <p className="py-4">2. Use 'public' before the table name. e.g. SELECT * FROM public.orders;</p> */}
                                                <p className="py-4">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                                <p className="py-4">3. To get the month from date use - extract(month from date_column)</p>
                                            </div>
                                        </dialog>
                                    </div>

                                    {/* <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400),theme(colors.sky.400),theme(colors.indigo.100),theme(colors.indigo.400))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p>
                            <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.green.300),theme(colors.green.100),theme(colors.sky.400),theme(colors.yellow.200),theme(colors.sky.400),theme(colors.green.100),theme(colors.green.300))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p>
                            <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p> */}


                                    <div className="mt-2 mb-2 font-semibold">
                                        <p className="flex justify-start text-xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p>
                                        {/* <p className="flex justify-start">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p> */}
                                    </div>
                                    {/* <h2 className="mt-8 mb-3 font-semibold"> Statement </h2> */}
                                    <div className="mt-2 mb-2">
                                        {question ? question[0].content : ''}
                                    </div>
                                    <h2 className="mt-5 mb-3 font-semibold"> Try this query </h2>
                                    <div className="mt-2 mb-3">
                                        {/* {question ? question[0].code : ''} */}
                                        <CodeMirror
                                            // className='codemirror_box'
                                            className=" h-full overflow-auto"
                                            extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                            // theme={codeTheme}
                                            // extensions={[sql({ dialect })]}
                                            height="4rem"
                                            // placeholder="SELECT * FROM public.orders"
                                            value={question ? question[0].code : ''}
                                            // onChange={handleQueryChange}
                                            options={{ readOnly: true }}
                                        // theme={"dark"}
                                        />
                                    </div>
                                    <h2 className="mt-4  mb-3 font-semibold"> Explanation </h2>
                                    <div className="mt-3 mb-3">
                                        {question ? question[0].explain : ''}
                                    </div>



                                </div>




                                <div className="shadow-sm">


                                    <Split
                                        // class="wrap2"
                                        sizes={[50, 50]}
                                        minSize={200}
                                        expandToMin={false}
                                        gutterSize={6}
                                        gutterAlign="center"
                                        snapOffset={30}
                                        dragInterval={1}
                                        direction="vertical"
                                        cursor="row-resize"
                                        className="overflow-hidden h-full"
                                    >

                                        <div className="border border-gray-300 rounded-md bg-white shadow-sm  flex flex-col h-full p-1">

                                            <p className="flex justify-start text-sm font-bold"> {'SQL Play Ground'} </p>
                                            {/* <p className="flex justify-start text-sm font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">{'SQL Play Ground'}</p> */}

                                            <CodeMirror
                                                // className='codemirror_box'
                                                className=" h-full overflow-auto"

                                                indentWithTab={false}
                                                // theme={codeTheme}
                                                extensions={[sql({ dialect })]}
                                                // height="14rem"
                                                placeholder="SELECT * FROM public.cars"
                                                value={sqlQuery}
                                                onChange={handleQueryChange}
                                            />

                                            <div className=" flex flex-row px-2">

                                                <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto">

                                                    <h2>
                                                        {isButtonDisabled ?
                                                            <span className="loading loading-infinity loading-lg"></span>

                                                            : ''
                                                        }
                                                    </h2>
                                                    <h2>

                                                        {correct === 'Correct' && <p className="text-green-600 font-bold">Your answer is Correct!</p>}


                                                        {correct === 'Incorrect' && <p className="text-red-600 font-bold">Your answer is Wrong </p>}



                                                    </h2>
                                                </div>

                                                <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto ">
                                                    <h3>
                                                        {queryerror ? queryerror : ''}
                                                    </h3>
                                                </div>


                                                <div className="w-1/4 flex justify-center">
                                                    <button onClick={executeQuery} type="button" disabled={isButtonDisabled} className="btn btn-neutral btn-sm xl:btn-sm 2xl:btn-md">
                                                        Execute Query
                                                    </button>
                                                </div>



                                            </div>

                                        </div>


                                        <div className="overflow-auto">


                                            <div className="flex justify-start w-full 
                    " >
                                                {results ?
                                                    <DataTable data={results}
                                                    />
                                                    : <span className="loading loading-infinity loading-lg"></span>
                                                }
                                            </div>


                                        </div>

                                    </Split>

                                </div>

                            </Split>


                        </div>
                    </div>




                </div >

            ) : (
                // Render this div for large screens

                <div className="relative flex flex-col w-10/12 h-screen overflow-hidden ">

                    <div className="navbar bg-base-100">
                        <div className="navbar-start">
                            <Link to="/" >
                                {/* <a className="btn btn-ghost normal-case text-xl"> SQL Guroo</a> */}
                                <img src={logo} alt="Logo" className="w-64 sm:mx-auto" />
                            </Link>
                            {/* <button className="btn-sm mx-1 sm:btn hidden sm:block" onClick={handlePrevPage}>
                            <FcPrevious />
                        </button>
                        <button className="btn-sm mx-1 sm:btn hidden sm:block" onClick={handleNextPage}>
                            <FcNext />
                        </button> */}

                        </div >

                        <div className="navbar-end">
                            <h1 className="normal-case font-semibold hidden sm:block text-base xl:text-xl">
                                Practice, Learn, and Master SQL!
                            </h1>
                        </div>

                    </div >

                    {/* `/question/${prevPage}` */}

                    {/* <div class="flex justify-center p-4 ">
                    <nav aria-label="Pagination">
                        <ul class="inline-flex items-center -space-x-px rounded-md text-sm shadow-sm">
                            <li>
                                <a href="#" class="inline-flex items-center space-x-2 rounded-l-md border border-gray-200 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                    </svg>
                                    <span>Previous</span>
                                </a>
                            </li>
                            <li>
                                <a href="/learnsql/1" aria-current="page" class="z-10 inline-flex items-center border border-gray-300 bg-gray-100 px-4 py-2 font-medium text-gray-700">1 </a>
                            </li>
                            <li>
                                <a href="/learnsql/2" class="inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-gray-500 hover:bg-gray-50">2 </a>
                            </li>
                            <li>
                                <span class="inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-gray-700">... </span>
                            </li>
                            <li>
                                <a href="#" class="inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-gray-500 hover:bg-gray-50">9 </a>
                            </li>
                            <li>
                                <a href="#" class="inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-gray-500 hover:bg-gray-50">10 </a>
                            </li>
                            <li>
                                <a href="#" class="inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                    <span>Next</span>
                                    <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </nav>
                </div> */}


                    <div className="flex justify-center">
                        <nav aria-label="Pagination">
                            <ul className="inline-flex items-center space-x-1 rounded-md text-sm">
                                <li>
                                    <button onClick={handlePrevPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z" clip-rule="evenodd" />
                                        </svg>
                                        <span>Previous</span>
                                    </button>
                                </li>
                                <li>
                                    <span className="inline-flex items-center rounded-md bg-white px-4 py-2 text-gray-500">Lesson <b className="mx-1">{id}</b> of <b className="ml-1">28</b></span>
                                </li>
                                <li>
                                    <button onClick={handleNextPage} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                        <span>Next</span>
                                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                            <path fill-rule="evenodd" d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z" clip-rule="evenodd" />
                                        </svg>
                                    </button>
                                </li>
                            </ul>
                        </nav>
                    </div>


                    {/* <button type="button" class="text-gray-500 hover:text-gray-600" data-hs-overlay="#docs-sidebar" aria-controls="docs-sidebar" aria-label="Toggle navigation">
                        <span class="sr-only">Toggle Navigation</span>
                        <svg class="flex-shrink-0 w-4 h-4" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                        </svg>
                    </button> */}
                    {/* 
                    <div id="docs-sidebar" class="hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden fixed top-0 start-0 bottom-0 z-[60] w-64 bg-white border-e border-gray-200 pt-7 pb-10 overflow-y-auto lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 dark:bg-gray-800 dark:border-gray-700">
                        <div class="px-6">
                            <a class="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand">Brand</a>
                        </div>
                        <nav class="hs-accordion-group p-6 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
                            <ul class="space-y-1.5">
                                <li>
                                    <a class="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-100 text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href="#">
                                        Dashboard
                                    </a>
                                </li>
                            </ul>
                        </nav>
                    </div> */}







                    <div class="mx-auto w-full h-full overflow-auto py-2">
                        <div class="h-full divide-y divide-gray-100 overflow-hidden border border-gray-200 rounded-md bg-white shadow-sm flex flex-row">


                            <div className="overflow-auto rounded-xl border border-gray-100 bg-gray-50 p-1 w-54">
                                <ul className="flex flex-col items-center gap-2 text-sm font-medium ">
                                    {lessons_array.map((item, index) => (
                                        <li key={index} className="w-full">
                                            <a
                                                href={`/learnsql/${item.id}`}
                                                className={`flex items-start justify-left gap-2 rounded-lg px-3 py-2 text-xs  2xl:text-sm	
                                                        ${selectedItem === index || item.id === parseInt(id, 10)
                                                        ? 'text-gray-700 bg-white shadow'
                                                        : 'text-gray-500 hover:bg-white hover:text-gray-700 hover:shadow'
                                                    }`}
                                                onClick={() => setSelectedItem(index)}
                                            >
                                                {item.Lesson}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>



                            <Split
                                // class="wrap"
                                sizes={[50, 50]}
                                minSize={100}
                                expandToMin={false}
                                gutterSize={10}
                                gutterAlign="center"
                                snapOffset={30}
                                dragInterval={1}
                                direction="horizontal"
                                cursor="col-resize"
                                className="split flex flex-row overflow-hidden h-full p-1 flex-1"
                            >

                                <div className="border border-gray-300 rounded-md bg-white shadow-sm p-3 overflow-auto flex">

                                    {/* <div>
                                        <div id="docs-sidebar" className="overflow-auto h-full hs-overlay hs-overlay-open:translate-x-0 -translate-x-full transition-all duration-300 transform hidden  top-0 start-0 bottom-0 z-[60] w-40 bg-white border-e border-gray-200 pt-7 pb-10  lg:block lg:translate-x-0 lg:end-auto lg:bottom-0 [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-gray-100 [&::-webkit-scrollbar-thumb]:bg-gray-300 dark:[&::-webkit-scrollbar-track]:bg-slate-700 dark:[&::-webkit-scrollbar-thumb]:bg-slate-500 dark:bg-gray-800 dark:border-gray-700">
                                            <div className="px-2">
                                                <a className="flex-none text-xl font-semibold dark:text-white" href="#" aria-label="Brand">Lessons</a>
                                            </div>
                                            <nav className="hs-accordion-group p-2 w-full flex flex-col flex-wrap" data-hs-accordion-always-open>
                                                <ul className="space-y-1.5 ">
                                                    {lessons_array.map((item, index) => (
                                                        <li key={index}>
                                                            <a className="flex items-center gap-x-3.5 py-2 px-2.5 bg-gray-100 text-sm text-slate-700 rounded-lg hover:bg-gray-100 dark:bg-gray-900 dark:text-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" href={`/learnsql/${item.id}`}>
                                                                {item.Lesson}
                                                            </a>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </nav>
                                        </div>
                                    </div> */}




                                    <div className="px-3 flex-1">
                                        <div className="overflow-auto   flex flex-row justify-between">

                                            {/* <Link to="/" >

                                                <button type="button"
                                                    className="btn normal-case text-sm xl:text-base flex btn-sm xl:btn-sm 2xl:btn-md">
                                                    <BsListCheck />
                                                    <span className="hidden lg:block">
                                                        All Questions
                                                    </span>
                                                </button>
                                            </Link>

                                            <button className="btn btn-warning normal-case text-sm xl:text-base btn-sm xl:btn-sm 2xl:btn-md" onClick={() => document.getElementById('my_modal_3').showModal()}>
                                                <SiPostgresql />
                                                <span>
                                                    PostgreSQL Syntax
                                                </span>
                                            </button> */}

                                            <dialog id="my_modal_3" className="modal">
                                                <div className="modal-box">
                                                    <form method="dialog">
                                                        {/* if there is a button in form, it will close the modal */}
                                                        <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                                    </form>
                                                    <h3 className="font-bold text-lg">PostgreSQL Syntax</h3>
                                                    <p className="py-4">1. Use PostgreSQL 14 version for SQL syntax</p>
                                                    {/* <p className="py-4">2. Use 'public' before the table name. e.g. SELECT * FROM public.orders;</p> */}
                                                    <p className="py-4">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                                    <p className="py-4">3. To get the month from date use - extract(month from date_column)</p>
                                                </div>
                                            </dialog>
                                        </div>

                                        {/* <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.indigo.400),theme(colors.indigo.100),theme(colors.sky.400),theme(colors.fuchsia.400),theme(colors.sky.400),theme(colors.indigo.100),theme(colors.indigo.400))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p>
                                            <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.green.300),theme(colors.green.100),theme(colors.sky.400),theme(colors.yellow.200),theme(colors.sky.400),theme(colors.green.100),theme(colors.green.300))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p>
                                            <p class="text-6xl md:text-9xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">Gradient Effect</p> */}


                                        <div className="mt-3 mb-3 font-semibold">
                                            <p className="flex justify-start text-xl font-extrabold bg-clip-text text-transparent bg-[linear-gradient(to_right,theme(colors.purple.400),theme(colors.purple.100),theme(colors.pink.300),theme(colors.orange.400),theme(colors.pink.300),theme(colors.purple.100),theme(colors.purple.400))] bg-[length:200%_auto] animate-gradient">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p>
                                            {/* <p className="flex justify-start">{question ? question[0].lesson : <span className="loading loading-infinity loading-lg"></span>}</p> */}
                                        </div>
                                        {/* <h2 className="mt-8 mb-3 font-semibold"> Statement </h2> */}
                                        <div className="mt-3 mb-3">
                                            {question ? question[0].content : ''}
                                        </div>
                                        <h2 className="mt-8 mb-3 font-semibold"> Try this query </h2>
                                        <div className="mt-3 mb-3">
                                            {/* {question ? question[0].code : ''} */}
                                            <CodeMirror
                                                // className='codemirror_box'
                                                className=" h-full overflow-auto"
                                                extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                                // theme={codeTheme}
                                                // extensions={[sql({ dialect })]}
                                                height="4rem"
                                                // placeholder="SELECT * FROM public.orders"
                                                value={question ? question[0].code : ''}
                                                // onChange={handleQueryChange}
                                                options={{ readOnly: true }}
                                            // theme={"dark"}
                                            />
                                        </div>
                                        <h2 className="mt-8  mb-3 font-semibold"> Explanation </h2>
                                        <div className="mt-3 mb-3">
                                            {question ? question[0].explain : ''}
                                        </div>

                                        {/* <h2 className="mt-3 mb-4 font-semibold"> 1. Schema </h2> */}

                                        {/* <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_schema1') && question[0].table_schema1} </h2>
                                        <div className="mt-3 mb-3 ">
                                            {question ?
                                                <DataTable data={question[0].id} />
                                                : <span className="loading loading-infinity loading-lg"></span>
                                            }
                                        </div> */}

                                    </div>

                                </div>




                                <div className="border border-gray-200 rounded-md bg-white shadow-sm p-3 ">


                                    <Split
                                        // class="wrap2"
                                        sizes={[50, 50]}
                                        minSize={200}
                                        expandToMin={false}
                                        gutterSize={10}
                                        gutterAlign="center"
                                        snapOffset={30}
                                        dragInterval={1}
                                        direction="vertical"
                                        cursor="row-resize"
                                        className="overflow-hidden h-full"
                                    >

                                        <div className="border border-gray-300 rounded-md bg-white shadow-sm  flex flex-col h-full p-1">

                                            {/* <p> {'Example syntax : SELECT * FROM ' + '<' + 'tablename' + '>' + '  e.g. table name - public.cars'} </p> */}
                                            <p className="flex justify-start text-sm font-bold"> {'SQL Play Ground'} </p>

                                            <CodeMirror
                                                // className='codemirror_box'
                                                className=" h-full overflow-auto"

                                                indentWithTab={false}
                                                // theme={codeTheme}
                                                extensions={[sql({ dialect })]}
                                                // height="14rem"
                                                placeholder="SELECT * FROM public.cars"
                                                value={sqlQuery}
                                                onChange={handleQueryChange}
                                            />

                                            <div className=" flex flex-row px-2">
                                                {/* <button onClick={executeQuery} type="button" class="text-gray-900 bg-white hover:bg-gray-100 border border-gray-200 focus:ring-4 focus:outline-none focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:focus:ring-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:bg-gray-700 mr-2 mb-2">
                                                        Execute Query
                                                    </button> */}
                                                <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto">
                                                    {/* <h2> {correct}</h2> */}

                                                    <h2>
                                                        {isButtonDisabled ?
                                                            <span className="loading loading-infinity loading-lg"></span>

                                                            : ''
                                                        }
                                                    </h2>
                                                    <h2>

                                                        {correct === 'Correct' && <p className="text-green-600 font-bold">Your answer is Correct!</p>}


                                                        {correct === 'Incorrect' && <p className="text-red-600 font-bold">Your answer is Wrong </p>}



                                                    </h2>
                                                </div>

                                                <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto ">
                                                    <h3>
                                                        {queryerror ? queryerror : ''}
                                                    </h3>
                                                </div>


                                                <div className="w-1/4 flex justify-center">
                                                    {/* <button onClick={executeQuery} type="button" disabled={isButtonDisabled} className="btn btn-neutral btn-sm xl:btn-sm 2xl:btn-md">
                                                        Run
                                                    </button> */}

                                                    {/* <button type="button" onClick={executeQuery} className="inline-flex items-center space-x-2 rounded-md border border-gray-300 bg-white px-4 py-2 font-medium text-gray-500 hover:bg-gray-50">
                                                        <span>Run</span>
                                                        <FaPlay />
                                                    </button> */}

                                                    <button type="button" onClick={executeQuery} className="inline-flex items-center gap-1.5 rounded-lg border border-green-500 bg-green-500 px-5 py-2.5 text-center text-sm font-medium text-white shadow-sm transition-all hover:border-green-700 hover:bg-green-700 focus:ring focus:ring-green-200 disabled:cursor-not-allowed disabled:border-green-300 disabled:bg-green-300">
                                                        <FaPlay />
                                                        <span>Run</span>
                                                    </button>

                                                </div>

                                                {/* <div className="w-1/4 flex justify-center">
                                                        <button onClick={submitQuery} type="button" disabled={isButtonDisabled} className="btn btn-primary btn-sm xl:btn-sm 2xl:btn-md">
                                                            Submit
                                                        </button>
                                                    </div> */}

                                            </div>

                                        </div>


                                        <div className="overflow-auto">


                                            <div className="flex justify-start w-full 
                                                  " >
                                                {results ?
                                                    <DataTable data={results}
                                                    />
                                                    : <span className="loading loading-infinity loading-lg"></span>
                                                }
                                            </div>


                                        </div>

                                    </Split>

                                </div>

                            </Split>


                        </div>
                    </div>




                </div >
            )
            }
        </div >

    );
}

export default Learnsql;




