import React, { useState, useEffect } from 'react';
import './App.css';
import axios from 'axios';
import CodeMirror from '@uiw/react-codemirror';
import { createTheme } from '@uiw/codemirror-themes';
import { sql, SQLDialect } from "@codemirror/lang-sql";
import { EditorView, keymap } from "@codemirror/view";
import Split from 'react-split'
import DataTable from './DataTable'; // Import your DataTable component
import { BrowserRouter as Router, Routes, Route, Link, Navigate, useParams } from 'react-router-dom';
import Home from './Home';
// const deepEqual = require('deep-equal');
// var deepEqual = require('fast-deep-equal');
import { FcPrevious, FcNext } from 'react-icons/fc';
import { BsListCheck } from 'react-icons/bs';
import { BiLogoPostgresql } from "react-icons/bi";
import { SiPostgresql } from "react-icons/si";
import { ToastContainer, toast } from 'react-toastify';
import { IoArrowBack } from "react-icons/io5";
import { IoArrowForward } from "react-icons/io5";
import { IoKey } from "react-icons/io5";
import { qs } from "qs"

import 'react-toastify/dist/ReactToastify.css';
import logo from './logo_img.png';


function Main() {
    const [sqlQuery, setSqlQuery] = useState('');
    const [results, setResults] = useState([]);
    const [correct, setCorrect] = useState('');
    const [loading, setLoading] = useState(true);
    const [question, setQuestion] = useState();
    const [queryerror, setQueryerror] = useState('');
    const { id } = useParams();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 640);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth <= 640);
        };

        // Attach the event listener
        window.addEventListener('resize', handleResize);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array ensures that the effect runs only once on mount



    // const backendUrl = 'https://sqlguru-service.onrender.com';
    const backendUrl = 'http://195.35.23.51:3001';



    const handlePrevPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const prevPage = parseInt(id, 10) - 1;
        // Redirect to the next page
        if (prevPage < 1) {
            return;
        }
        window.location.href = `/question/${prevPage}`;
    };

    const handleNextPage = () => {
        // Assuming page is a string, you might want to convert it to a number
        const nextPage = parseInt(id, 10) + 1;
        // Redirect to the next page
        if (nextPage >= 10000) {
            return;
        }
        window.location.href = `/question/${nextPage}`;
    };



    const handleQueryChange = (e) => {
        setSqlQuery(e);
    };



    const getItemData = async () => {
        try {
            const response = await axios.get(`https://sqlguroo.com/api/get-specific-data/${id}`);
            const specificData = response.data;
            setQuestion(specificData)
            console.log(specificData);
        } catch (error) {
            console.error('Error fetching specific data:', error);
        }
    };



    const executeQuery = async () => {
        setLoading(true);
        setCorrect('')
        setButtonDisabled(true);

        setTimeout(async () => {

            if (/DROP|ALTER/i.test(sqlQuery)) {
                setQueryerror('Only Select statements are allowed');
                console.log('Only Select statements are allowed')
                setResults([])
                setTimeout(async () => {
                    setButtonDisabled(false);
                }, 1400);
                return;
            }

            try {

                // let res = await axios.post('https://sqlguru-service.onrender.com/executeQuery/', { sqlQuery })

                let res = await axios.post('https://sqlguroo.com/api/executeQuery/', { sqlQuery }
                    , {
                        timeout: 5000, // Set the timeout to 5 seconds (5000 milliseconds)
                    }
                )

                let { data } = res.data;
                setLoading(false);
                setResults(res.data)
                // console.log(res.data)
                console.log(results)
                setQueryerror('')
            }
            catch (e) {
                if (axios.isCancel(e)) {
                    console.log('Request canceled:', e.message)
                    setQueryerror(e.message)
                    setResults([])
                } else {
                    console.log(e.response ? e.response.data.error : e)
                    setQueryerror(e.response ? e.response.data.error : 'Unknown Error')
                    setResults([])
                }
            }

            setButtonDisabled(false);

        }, 1400);
    };



    function areTablesEqual(tableA, tableB) {
        // Check if the tables have the same number of rows
        if (tableA.length !== tableB.length) {
            return false;
        }

        // Create arrays to store sorted row data for comparisonss
        const sortedTableA = [];
        const sortedTableB = [];

        // Sort the rows in both tables by their values
        for (const row of tableA) {
            const sortedRow = Object.values(row).sort();
            sortedTableA.push(sortedRow);
        }

        for (const row of tableB) {
            const sortedRow = Object.values(row).sort();
            sortedTableB.push(sortedRow);
        }

        // Sort the entire array to ensure consistent order
        sortedTableA.sort();
        sortedTableB.sort();

        // Compare the sorted arrays
        for (let i = 0; i < sortedTableA.length; i++) {
            for (let j = 0; j < sortedTableA[i].length; j++) {
                if (sortedTableA[i][j] !== sortedTableB[i][j]) {
                    return false;
                }
            }
        }

        return true;
    }




    const submitQuery = async () => {
        if (results.length !== 0 && question !== null) {
            // Perform your processing on 'result' here
            // For example, you can log it or update other state variables

            setButtonDisabled(true)
            setCorrect('')

            setTimeout(async () => {

                try {
                    const jsonString1 = JSON.stringify(question[0].output, null, 2);
                    const jsonString2 = JSON.stringify(results, null, 2);

                    // const jsonString1 = JSON.stringify(question[0].output, Object.keys(question[0].output).sort());
                    // const jsonString2 = JSON.stringify(results, Object.keys(results).sort());

                    console.log(jsonString1)
                    console.log(jsonString2)

                    // const areEqual = jsonString1 === jsonString2;

                    const areEqual = areTablesEqual(question[0].output, results);


                    if (areEqual) {
                        setCorrect('Correct');
                    }
                    else {
                        setCorrect('Incorrect');
                    }
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                    setCorrect('false');
                }
                setButtonDisabled(false)

            }, 1400);

        }
    };




    useEffect(() => {
        getItemData()
    }
        , []);


    const KEYWORDS = [
        "select",
        "from",
        "where",
        "and",
        "or",
        "not",
        "in",
        "between",
        "contains",
        "array",
        "limit",
        "offset",
        "union",
        "intersect",
        "except",
        "order by",
        "asc",
        "desc"
    ];

    const TENSORS = ["images", "labels"];

    const dialect = SQLDialect.define({
        keywords: KEYWORDS.join(" "),
        builtin: TENSORS.join(" ")
    });


    return (
        <div className="relative flex h-screen overflow-hidden justify-center">


            {isSmallScreen ? (
                // Render this div for small screens


                <div className="relative flex flex-col h-screen overflow-hidden">


                    <div className="navbar bg-base-100">
                        <div className="navbar-start">
                            <Link to="/" >
                                {/* <a className="btn btn-ghost normal-case text-xl"> SQL Guroo</a> */}
                                <img src={logo} alt="Logo" className="w-64 sm:mx-auto" />
                            </Link>
                            <button className="btn-sm mx-1 sm:btn hidden sm:block" onClick={handlePrevPage}>
                                <FcPrevious />
                            </button>
                            <button className="btn-sm mx-1 sm:btn hidden sm:block" onClick={handleNextPage}>
                                <FcNext />
                            </button>

                        </div>

                        <div className="navbar-end">
                            <h1 className="normal-case font-semibold hidden sm:block md:text-xl">
                                Practice, Learn, and Master SQL!
                            </h1>
                        </div>


                    </div>

                    <Split
                        // class="wrap"
                        sizes={[40, 60]}
                        minSize={100}
                        expandToMin={false}
                        gutterSize={6}
                        gutterAlign="center"
                        snapOffset={30}
                        dragInterval={1}
                        direction="vertical"
                        cursor="col-resize"
                        className="flex flex-col overflow-hidden h-full p-1"
                    >

                        <div className="border border-gray-200 rounded-md bg-white shadow-sm p-2 overflow-auto">

                            <div className="overflow-auto   flex flex-row justify-between">

                                {/* <Link to="/" >

                                    <button type="button"
                                        className="btn normal-case text-sm flex btn-sm xl:btn-sm 2xl:btn-md">
                                        <IoArrowBack />
                                    </button>
                                </Link> */}

                                <Link>

                                    <button type="button" onClick={handlePrevPage}
                                        className="btn normal-case text-sm flex btn-sm xl:btn-sm 2xl:btn-md">
                                        {/* <BsListCheck /> */}
                                        <IoArrowBack />
                                        {/* All Questions */}
                                    </button>
                                </Link>

                                <Link>

                                    <button type="button" onClick={handleNextPage}
                                        className="btn normal-case text-sm flex btn-sm xl:btn-sm 2xl:btn-md">
                                        {/* <BsListCheck /> */}
                                        <IoArrowForward />
                                        {/* All Questions */}
                                    </button>
                                </Link>


                                <button className="btn btn-success normal-case text-sm btn-sm xl:btn-sm 2xl:btn-md" onClick={() => document.getElementById('my_modal_2').showModal()}>
                                    <IoKey />
                                    <span>
                                        Solution
                                    </span>
                                </button>
                                <dialog id="my_modal_2" className="modal">
                                    <div className="modal-box">
                                        <form method="dialog">
                                            {/* if there is a button in form, it will close the modal */}
                                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                        </form>
                                        <h3 className="font-bold text-lg">Solution</h3>
                                        {/* <p className="py-4">{question ? question[0].question : ''}</p> */}

                                        <CodeMirror
                                            // className='codemirror_box'
                                            className=" h-full overflow-auto"
                                            extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                            // theme={codeTheme}
                                            // extensions={[sql({ dialect })]}
                                            height="14rem"
                                            // placeholder="SELECT * FROM public.orders"
                                            value={question ? question[0].solution : ''}
                                            // onChange={handleQueryChange}
                                            options={{ readOnly: true }}
                                            theme={"dark"}
                                        />

                                    </div>
                                </dialog>

                                {/* You can open the modal using document.getElementById('ID').showModal() method */}
                                <button className="btn btn-warning normal-case text-sm btn-sm xl:btn-sm 2xl:btn-md" onClick={() => document.getElementById('my_modal_3').showModal()}> <SiPostgresql />
                                    PostgreSQL Syntax</button>
                                <dialog id="my_modal_3" className="modal">
                                    <div className="modal-box">
                                        <form method="dialog">
                                            {/* if there is a button in form, it will close the modal */}
                                            <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
                                        </form>
                                        <h3 className="font-bold text-lg">PostgreSQL Syntax</h3>
                                        <p className="py-4">1. Use PostgreSQL 14 version for SQL syntax</p>
                                        {/* <p className="py-4">2. Use 'public' before the table name. e.g. SELECT * FROM public.orders;</p> */}
                                        <p className="py-4">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                        <p className="py-4">3. To get the month from date use - extract(month from date_column)</p>
                                    </div>
                                </dialog>
                            </div>



                            <div className="mt-3 mb-4 font-semibold">
                                <p className="flex justify-start">{question ? question[0].question : ''}</p>
                            </div>
                            <div className="mt-3 mb-3">
                                {question ? question[0].html : ''}
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> 1. Schema </h2>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_schema1') && question[0].table_schema1} </h2>
                            <div className="mt-3 mb-3 ">
                                {question ?
                                    <DataTable data={question[0].schema} />
                                    : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_schema2') && question[0].table_schema2} </h2>
                            <div className="mt-3 mb-3 ">
                                {question && question[0].hasOwnProperty('schema2') &&
                                    <DataTable data={question[0].schema2} />
                                    // : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_schema3') && question[0].table_schema3} </h2>
                            <div className="mt-3 mb-3 ">
                                {question && question[0].hasOwnProperty('schema3') &&
                                    <DataTable data={question[0].schema3} />
                                    // : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold">2. Data table </h2>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_name1') && question[0].table_name1} </h2>
                            <div className="mt-3 mb-3 overflow-auto">
                                {question ?
                                    <DataTable data={question[0].table} />
                                    : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_name2') && question[0].table_name2} </h2>
                            <div className="mt-3 mb-3 overflow-auto">
                                {question && question[0].hasOwnProperty('table2') &&
                                    <DataTable data={question[0].table2} />
                                    // : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> {question && question[0].hasOwnProperty('table_name3') && question[0].table_name3} </h2>
                            <div className="mt-3 mb-3 overflow-auto">
                                {question && question[0].hasOwnProperty('table3') &&
                                    <DataTable data={question[0].table3} />
                                    // : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                            <h2 className="mt-3 mb-4 font-semibold"> 3. Example Output</h2>

                            <div className="mt-3 mb-3 overflow-auto">
                                {question ?
                                    <DataTable data={question[0].example_output} />
                                    : <span className="loading loading-infinity loading-lg"></span>
                                }
                            </div>

                        </div>


                        <div className="border border-gray-200 rounded-md bg-white shadow-sm	p-1">


                            <Split
                                // class="wrap2"
                                sizes={[50, 50]}
                                minSize={200}
                                expandToMin={false}
                                gutterSize={6}
                                gutterAlign="center"
                                snapOffset={30}
                                dragInterval={1}
                                direction="vertical"
                                cursor="row-resize"
                                className="overflow-hidden h-full"
                            >

                                <div className="border border-gray-300 rounded-md bg-white shadow-sm	flex flex-col p-1">

                                    {/* <p> {'Example syntax : SELECT * FROM ' + '<' + 'tablename' + '>' + '  e.g. table name - public.orders'} </p> */}
                                    <p className="flex justify-start text-sm font-bold"> {'SQL Play Ground'} </p>
                                    <CodeMirror
                                        // className='codemirror_box'
                                        className=" h-full overflow-auto"

                                        indentWithTab={false}
                                        // theme={codeTheme}
                                        extensions={[sql({ dialect })]}
                                        height="6rem"
                                        placeholder="SELECT * FROM public.orders"
                                        value={sqlQuery}
                                        onChange={handleQueryChange}
                                    />

                                    <div className=" flex flex-row px-2">

                                        <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto">


                                            <h2>
                                                {isButtonDisabled ?
                                                    <span className="loading loading-infinity loading-lg"></span>

                                                    : ''
                                                }
                                            </h2>
                                            <h2>

                                                {correct === 'Correct' && <p className="text-green-600 font-bold">Your answer is Correct!</p>}


                                                {correct === 'Incorrect' && <p className="text-red-600 font-bold">Your answer is Wrong </p>}

                                            </h2>
                                        </div>

                                        <div className=" flex text-sm 2xl:text-base font-semibold w-1/4 justify-center items-center overflow-auto ">
                                            <h3>
                                                {queryerror ? queryerror : ''}
                                            </h3>
                                        </div>

                                        <div className="w-1/4 flex justify-center">
                                            <button onClick={executeQuery} type="button" disabled={isButtonDisabled} className="btn btn-neutral btn-xs xl:btn-sm 2xl:btn-md">
                                                Execute
                                            </button>
                                        </div>

                                        <div className="w-1/4 flex justify-center">
                                            <button onClick={submitQuery} type="button" disabled={isButtonDisabled} className="btn btn-primary btn-xs xl:btn-sm 2xl:btn-md">
                                                Submit
                                            </button>
                                        </div>



                                    </div>

                                </div>


                                <div className="overflow-auto">

                                    <div className="flex justify-start w-full" >
                                        {results ?
                                            <DataTable data={results}
                                            />
                                            : <span className="loading loading-infinity loading-lg"></span>
                                        }
                                    </div>


                                </div>

                            </Split>

                        </div>

                    </Split>



                </div >



            ) : (
                // Render this div for large screens

                <div className="relative flex flex-col w-10/12 h-screen overflow-hidden">


                    <nav className="bg-white border-b border-gray-200 px-4 py-2.5 shadow-sm">
                        <div className="flex justify-between items-center w-full">
                            {/* Left side: Logo and Navigation - Now with full width */}
                            <div className="flex items-center gap-2">
                                {/* Logo */}
                                <Link to="/" className="flex-shrink-0">
                                    <img
                                        src={logo}
                                        alt="SQL Guroo Logo"
                                        className="w-48 md:w-64 h-auto"
                                    />
                                </Link>

                                {/* Navigation Buttons */}
                                <div className="hidden sm:flex items-center gap-1">
                                    <button
                                        onClick={handlePrevPage}
                                        className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                                        aria-label="Previous page"
                                    >
                                        <FcPrevious className="text-xl" />
                                    </button>
                                    <button
                                        onClick={handleNextPage}
                                        className="p-2 rounded-lg hover:bg-gray-100 transition-colors"
                                        aria-label="Next page"
                                    >
                                        <FcNext className="text-xl" />
                                    </button>
                                </div>
                            </div>

                            {/* Right side: Tagline - Now pushed to the far right */}
                            <div className="hidden sm:block ml-auto">
                                <h1 className="text-base xl:text-xl font-semibold text-gray-700 whitespace-nowrap">
                                    Practice, Learn, and Master SQL!
                                </h1>
                            </div>
                        </div>
                    </nav>

                    <Split
                        // class="wrap"
                        sizes={[40, 60]}
                        minSize={100}
                        expandToMin={false}
                        gutterSize={10}
                        gutterAlign="center"
                        snapOffset={30}
                        dragInterval={1}
                        direction="horizontal"
                        cursor="col-resize"
                        className="split flex flex-row overflow-hidden h-full"
                    >

                        <div className="border border-gray-200 rounded-md bg-white shadow-sm p-3 overflow-auto">

                            <div className="flex flex-row justify-between items-center gap-3 p-2 overflow-auto">
                                {/* All Questions Button */}
                                <Link to="/">
                                    <button type="button" className="inline-flex items-center gap-2 px-3 py-2 bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg transition-colors text-sm xl:text-base">
                                        <BsListCheck className="text-lg" />
                                        <span className="hidden 2xl:inline">
                                            All Questions
                                        </span>
                                    </button>
                                </Link>

                                {/* Solution Button */}
                                <button
                                    onClick={() => document.getElementById('my_modal_2').showModal()}
                                    className="inline-flex items-center gap-2 px-3 py-2 bg-emerald-500 hover:bg-emerald-600 text-white rounded-lg transition-colors text-sm xl:text-base"
                                >
                                    <IoKey className="text-lg" />
                                    <span>Solution</span>
                                </button>

                                {/* Solution Modal */}
                                <dialog id="my_modal_2" className="modal">
                                    <div className="modal-box max-w-3xl bg-white rounded-lg shadow-lg">
                                        <div className="flex items-center justify-between mb-4">
                                            <h3 className="text-xl font-semibold text-gray-800">Solution</h3>
                                            <form method="dialog">
                                                <button className="btn btn-sm btn-circle btn-ghost hover:bg-gray-100">✕</button>
                                            </form>
                                        </div>

                                        <div className="bg-gray-50 rounded-lg p-1">
                                            <CodeMirror
                                                className="min-h-[14rem] rounded-md overflow-auto"
                                                extensions={[sql({ dialect }), EditorView.lineWrapping]}
                                                height="14rem"
                                                value={question ? question[0].solution : ''}
                                                options={{ readOnly: true }}
                                                theme="dark"
                                            />
                                        </div>
                                    </div>
                                </dialog>

                                {/* PostgreSQL Syntax Button */}
                                <button
                                    onClick={() => document.getElementById('my_modal_3').showModal()}
                                    className="inline-flex items-center gap-2 px-3 py-2 bg-blue-500 hover:bg-blue-600 text-white rounded-lg transition-colors text-sm xl:text-base"
                                >
                                    <SiPostgresql className="text-lg" />
                                    <span>PostgreSQL Syntax</span>
                                </button>


                                {/* PostgreSQL Syntax Modal */}
                                <dialog id="my_modal_3" className="modal">
                                    <div className="modal-box max-w-2xl bg-white rounded-lg shadow-lg">
                                        <div className="flex items-center justify-between mb-4">
                                            <h3 className="text-xl font-semibold text-gray-800">PostgreSQL Syntax</h3>
                                            <form method="dialog">
                                                <button className="btn btn-sm btn-circle btn-ghost hover:bg-gray-100">✕</button>
                                            </form>
                                        </div>

                                        <div className="space-y-4 text-gray-700">
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                                <p className="font-medium">1. Use PostgreSQL 14 version for SQL syntax</p>
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                                <p className="font-medium">2. To apply date condition use, - date_column = date '2020-01-01'</p>
                                            </div>
                                            <div className="p-3 bg-gray-50 rounded-lg">
                                                <p className="font-medium">3. To get the month from date use - extract(month from date_column)</p>
                                            </div>
                                        </div>
                                    </div>
                                </dialog>
                            </div>

                            {/* <Route exact path="/" element={<Home />} /> */}

                            {/* <button onClick={getItemData}
                        type="button"
                        className="inline-block rounded bg-neutral-800 px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-neutral-50 shadow-[0_4px_9px_-4px_rgba(51,45,45,0.7)] transition duration-150 ease-in-out hover:bg-neutral-800 hover:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:bg-neutral-800 focus:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] focus:outline-none focus:ring-0 active:bg-neutral-900 active:shadow-[0_8px_9px_-4px_rgba(51,45,45,0.2),0_4px_18px_0_rgba(51,45,45,0.1)] dark:bg-neutral-900 dark:shadow-[0_4px_9px_-4px_#030202] dark:hover:bg-neutral-900 dark:hover:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:focus:bg-neutral-900 dark:focus:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)] dark:active:bg-neutral-900 dark:active:shadow-[0_8px_9px_-4px_rgba(3,2,2,0.3),0_4px_18px_0_rgba(3,2,2,0.2)]">
                        Get the Question
                    </button> */}

                            <div className="max-w-6xl mx-auto p-6 bg-white rounded-lg shadow-sm">
                                {/* Question Section */}
                                <div className="mb-8 space-y-2">
                                    <p className="text-xl font-semibold text-gray-800">
                                        {question ? question[0].question : ''}
                                    </p>
                                    <div className="prose max-w-none text-gray-600">
                                        {question ? question[0].html : ''}
                                    </div>
                                </div>

                                {/* Schema Section */}
                                <div className="mb-8">
                                    <h2 className="text-xl font-semibold text-gray-800 mb-6 pb-2 border-b">1. Schema</h2>

                                    <div className="space-y-6">
                                        {/* First Schema */}
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-lg text-gray-700 mb-4">
                                                {question && question[0].hasOwnProperty('table_schema1') && question[0].table_schema1}
                                            </h3>
                                            <div className="bg-white rounded-lg shadow-sm">
                                                {question ?
                                                    <DataTable data={question[0].schema} />
                                                    : <span className="loading loading-infinity loading-lg"></span>
                                                }
                                            </div>
                                        </div>

                                        {/* Second Schema */}
                                        {question && question[0].hasOwnProperty('schema2') && (
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <h3 className="text-lg text-gray-700 mb-4">
                                                    {question[0].table_schema2}
                                                </h3>
                                                <div className="bg-white rounded-lg shadow-sm">
                                                    <DataTable data={question[0].schema2} />
                                                </div>
                                            </div>
                                        )}

                                        {/* Third Schema */}
                                        {question && question[0].hasOwnProperty('schema3') && (
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <h3 className="text-lg text-gray-700 mb-4">
                                                    {question[0].table_schema3}
                                                </h3>
                                                <div className="bg-white rounded-lg shadow-sm">
                                                    <DataTable data={question[0].schema3} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Data Table Section */}
                                <div className="mb-8">
                                    <h2 className="text-xl font-semibold text-gray-800 mb-6 pb-2 border-b">2. Data Table</h2>

                                    <div className="space-y-6">
                                        {/* First Table */}
                                        <div className="bg-gray-50 p-4 rounded-lg">
                                            <h3 className="text-lg text-gray-700 mb-4">
                                                {question && question[0].hasOwnProperty('table_name1') && question[0].table_name1}
                                            </h3>
                                            <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                {question ?
                                                    <DataTable data={question[0].table} />
                                                    : <span className="loading loading-infinity loading-lg"></span>
                                                }
                                            </div>
                                        </div>

                                        {/* Second Table */}
                                        {question && question[0].hasOwnProperty('table2') && (
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <h3 className="text-lg text-gray-700 mb-4">
                                                    {question[0].table_name2}
                                                </h3>
                                                <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                    <DataTable data={question[0].table2} />
                                                </div>
                                            </div>
                                        )}

                                        {/* Third Table */}
                                        {question && question[0].hasOwnProperty('table3') && (
                                            <div className="bg-gray-50 p-4 rounded-lg">
                                                <h3 className="text-lg text-gray-700 mb-4">
                                                    {question[0].table_name3}
                                                </h3>
                                                <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                                    <DataTable data={question[0].table3} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                {/* Example Output Section */}
                                <div>
                                    <h2 className="text-xl font-semibold text-gray-800 mb-6 pb-2 border-b">3. Example Output</h2>
                                    <div className="bg-gray-50 p-4 rounded-lg">
                                        <div className="bg-white rounded-lg shadow-sm overflow-auto">
                                            {question ?
                                                <DataTable data={question[0].example_output} />
                                                : <span className="loading loading-infinity loading-lg"></span>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="border border-gray-200 rounded-md bg-white shadow-sm p-3 ">


                            <Split
                                // class="wrap2"
                                sizes={[50, 50]}
                                minSize={200}
                                expandToMin={false}
                                gutterSize={10}
                                gutterAlign="center"
                                snapOffset={30}
                                dragInterval={1}
                                direction="vertical"
                                cursor="row-resize"
                                className="overflow-hidden h-full"
                            >

                                <div className="flex flex-col h-full bg-white rounded-lg border border-gray-200 shadow-sm">
                                    {/* Header */}
                                    <div className="px-4 py-3 border-b border-gray-200">
                                        <h2 className="text-lg font-semibold text-gray-800">SQL Playground</h2>
                                    </div>

                                    {/* Code Editor */}
                                    <div className="flex-grow p-2 bg-gray-50">
                                        <CodeMirror
                                            className="h-full overflow-auto rounded-md"
                                            indentWithTab={false}
                                            extensions={[sql({ dialect })]}
                                            placeholder="SELECT * FROM public.orders"
                                            value={sqlQuery}
                                            onChange={handleQueryChange}
                                        />
                                    </div>

                                    {/* Action Bar */}
                                    <div className="grid grid-cols-4 gap-2 p-3 bg-gray-50 border-t border-gray-200">
                                        {/* Status Message */}
                                        <div className="flex items-center justify-center">
                                            {isButtonDisabled ? (
                                                <span className="loading loading-infinity loading-lg"></span>
                                            ) : (
                                                <div className="text-sm font-medium">
                                                    {correct === 'Correct' && (
                                                        <p className="text-emerald-600 flex items-center gap-1">
                                                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                                                            </svg>
                                                            Correct Answer!
                                                        </p>
                                                    )}
                                                    {correct === 'Incorrect' && (
                                                        <p className="text-red-600 flex items-center gap-1">
                                                            <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 20 20">
                                                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                                                            </svg>
                                                            Wrong Answer
                                                        </p>
                                                    )}
                                                </div>
                                            )}
                                        </div>

                                        {/* Error Message */}
                                        <div className="flex items-center justify-center">
                                            {queryerror && (
                                                <p className="text-sm font-medium text-red-600">{queryerror}</p>
                                            )}
                                        </div>

                                        {/* Execute Button */}
                                        <div className="flex items-center justify-center">
                                            <button
                                                onClick={executeQuery}
                                                disabled={isButtonDisabled}
                                                className="inline-flex items-center px-4 py-2 bg-gray-800 hover:bg-gray-700 disabled:bg-gray-400 text-white text-sm font-medium rounded-md transition-colors"
                                            >
                                                <span className="mr-2">▶</span>
                                                Execute Query
                                            </button>
                                        </div>

                                        {/* Submit Button */}
                                        <div className="flex items-center justify-center">
                                            <button
                                                onClick={submitQuery}
                                                disabled={isButtonDisabled}
                                                className="inline-flex items-center px-4 py-2 bg-blue-600 hover:bg-blue-700 disabled:bg-blue-400 text-white text-sm font-medium rounded-md transition-colors"
                                            >
                                                <span className="mr-2">✓</span>
                                                Submit
                                            </button>
                                        </div>
                                    </div>
                                </div>


                                <div className="w-full h-full bg-white rounded-lg border border-gray-200 shadow-sm p-4 overflow-hidden">
                                    <div className="h-full overflow-auto">
                                        {results ? (
                                            <div className="min-w-full">
                                                <DataTable data={results} />
                                            </div>
                                        ) : (
                                            <div className="flex justify-center items-center min-h-[200px]">
                                                <span className="loading loading-infinity loading-lg text-gray-400"></span>
                                            </div>
                                        )}
                                    </div>
                                </div>

                            </Split>

                        </div>

                    </Split>

                    {/* <footer className="footer items-center p-4 bg-neutral text-neutral-content">
                    <aside className="items-center grid-flow-col">
                        <svg width="36" height="36" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fillRule="evenodd" clipRule="evenodd" className="fill-current"><path d="M22.672 15.226l-2.432.811.841 2.515c.33 1.019-.209 2.127-1.23 2.456-1.15.325-2.148-.321-2.463-1.226l-.84-2.518-5.013 1.677.84 2.517c.391 1.203-.434 2.542-1.831 2.542-.88 0-1.601-.564-1.86-1.314l-.842-2.516-2.431.809c-1.135.328-2.145-.317-2.463-1.229-.329-1.018.211-2.127 1.231-2.456l2.432-.809-1.621-4.823-2.432.808c-1.355.384-2.558-.59-2.558-1.839 0-.817.509-1.582 1.327-1.846l2.433-.809-.842-2.515c-.33-1.02.211-2.129 1.232-2.458 1.02-.329 2.13.209 2.461 1.229l.842 2.515 5.011-1.677-.839-2.517c-.403-1.238.484-2.553 1.843-2.553.819 0 1.585.509 1.85 1.326l.841 2.517 2.431-.81c1.02-.33 2.131.211 2.461 1.229.332 1.018-.21 2.126-1.23 2.456l-2.433.809 1.622 4.823 2.433-.809c1.242-.401 2.557.484 2.557 1.838 0 .819-.51 1.583-1.328 1.847m-8.992-6.428l-5.01 1.675 1.619 4.828 5.011-1.674-1.62-4.829z"></path></svg>
                        <p>Copyright © 2023 - All right reserved - SQL Guru</p>
                    </aside>
                    <nav className="grid-flow-col gap-4 md:place-self-center md:justify-self-end">
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M24 4.557c-.883.392-1.832.656-2.828.775 1.017-.609 1.798-1.574 2.165-2.724-.951.564-2.005.974-3.127 1.195-.897-.957-2.178-1.555-3.594-1.555-3.179 0-5.515 2.966-4.797 6.045-4.091-.205-7.719-2.165-10.148-5.144-1.29 2.213-.669 5.108 1.523 6.574-.806-.026-1.566-.247-2.229-.616-.054 2.281 1.581 4.415 3.949 4.89-.693.188-1.452.232-2.224.084.626 1.956 2.444 3.379 4.6 3.419-2.07 1.623-4.678 2.348-7.29 2.04 2.179 1.397 4.768 2.212 7.548 2.212 9.142 0 14.307-7.721 13.995-14.646.962-.695 1.797-1.562 2.457-2.549z"></path></svg>
                        </a>
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z"></path></svg></a>
                        <a><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className="fill-current"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"></path></svg></a>
                    </nav>
                </footer> */}

                </div >


            )}






        </div >
    );
}

export default Main;




